@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/style/common';

.news-wrapper {
  p {
    @include font-size-large-1;
  }

  p.card-text {
    @extend .font-size-standard;
    color: var(--dark-accent-2);
  }

  .card-tile {
    border: 0;
    color: var(--accent-gray);
    margin: 0 auto;
    width: 50%;

    .typewriter-text {
      @extend .font-style-accent;
      @include font-size-large-1;
      color: var(--header-grey);
      font-weight: bold;
    }
  }

  .card-title {
    color: var(--header-grey);
  }

  .sub-title {
    @include font-size-small;
    color: var(--paragraph-gray-1);
    font-weight: bold;
  }

  .text-fields {
    @extend %base-text-fields;
  }

  .text-fields {
    @extend %base-text-fields;
  }

  .dangerous p {
    @extend .font-size-standard;
    color: var(--dark-accent-2);
  }

  .news-card {
    color: var(--accent-gray);
    height: 100%;
  }

  .no-link {
    text-decoration: none;
  }

  .high-light-1 {
    color: var(--paragraph-gray-2);
    font-weight: bold;
  }

  .highlighted-text {
    color: var(--logo-accent-color) !important;
    font-weight: bold;
  }

  .dangerous span {
    color: var(--logo-accent-color) !important;
    font-weight: bold;
  }

  .block-1 {
    height: 290px;
  }

  .block-2 {
    height: 520px;
  }

  .block-3 {
    height: 100%;
  }

  .block-4 {
    padding: 0;
  }

  .block-mobile {
    height: 100%;
    padding: 0;
  }

  @media all and (min-width: 824px) {
    .high-light-1 {
      @include font-size-large-2;
      color: var(--paragraph-gray-2);
      font-weight: bold;
      margin: unset;
    }

    .badge-hashicorp {
      width: 20%;
      margin-left: 40%;
    }

    .aws-image {
      width: 45%;
      margin-left: 27%;
    }

    .aws-badge {
      width: 20%;
      margin-left: 40%;
    }

    .aws-cert {
      width: 25%;
      margin-left: 37.5%;
    }

    .aws-ai-practitioner-cert {
      width: 40%;
      margin-left: 30%;
    }

    .ckad-cert {
      width: 23%;
      margin-left: 38.5%;
    }

    .gcp-ace-cert {
      width: 26%;
      margin-left: 37%;
    }

    .badge-spring {
      width: 20%;
      margin-left: 40%;
    }
  }

  @media all and (max-width: 825px) {
    .box {
      display: block;
      float: none;
      width: 100%;
    }

    .high-light {
      @extend .high-light;
      @include font-size-large-3;
    }

    .high-light-1 {
      @include font-size-large-1;
      color: var(--paragraph-gray-2);
      font-weight: bold;
      margin: unset;
    }

    .badge-hashicorp {
      width: 60%;
      margin-left: 20%;
    }

    .aws-badge {
      width: 60%;
      margin-left: 20%;
    }

    .gcp-ace-cert {
      width: 100%;
      margin-left: 0;
    }

    .badge-spring {
      width: 50%;
      margin-left: 25%;
    }
  }
}
