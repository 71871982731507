@import 'src/style/colors';
@import 'src/style/fonts';

%base-text-fields {
  text-align: center;
}

%base-high-light {
  @include font-style-highlight;
  color: $paragraph-gray-2;
  font-size: 38px;
  font-weight: bold;
}

%base-high-light-mobile {
  @include font-style-highlight;
  color: $paragraph-gray-2;
  font-size: 30px;
  font-weight: bold;
}

