@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/style/common';

.banner-wrapper {
  .text-fields {
    @extend %base-text-fields;
  }

  .high-light {
    @extend %base-high-light;
  }

  @media all and (max-width: 768px) {
    .high-light {
      @extend %base-high-light;
      @include font-size-large-3;
    }
  }
}
