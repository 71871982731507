@media all and (max-width: 824px) {
  .red {
    margin-top: -76px;
  }
}

@media all and (min-width: 825px) {
  .red {
    margin-top: -76px;
  }
}

@media all and (min-width: 992px) {
  .red {
    margin-top: -84px;
  }
}
