@import 'src/style/colors';
@import 'src/style/fonts';

.footer-wrapper {
  .footer-text-small {
    @extend .font-size-small;
    color: var(--paragraph-gray-2);
    text-align: center;
  }

  .footer-style {
    border-top: 0.75px dashed var(--dark-accent);
  }

  .footer-text {
    @extend .font-style-accent;
    @extend .font-size-standard;
    color: var(--paragraph-gray-2);
    font-weight: bold;
    text-align: center;
  }

  .footer-text-2 {
    @extend .font-size-small;
    color: var(--paragraph-gray-2);
    text-align: center;
  }

  .text-fields {
    text-align: center;
  }

  .typewriter-text {
    @extend .font-style-accent;
  }

  .badge-secondary {
    background-color: var(--warm-grey-2);
    color: var(--white);
  }

  .btn-info-local {
    @extend .font-size-medium;
    background-color: var(--paragraph-gray-2) !important;
    border: 0 !important;
    color: var(--paragraph-gray-2) !important;
  }

  .tag-style {
    text-align: center;
  }
}
