@import 'src/style/colors';
@import 'src/style/fonts';

.header-wrapper {
  a:active {
    color: var(--button-background);
  }

  a:hover {
    text-decoration: none;
  }

  button.dropdown-toggle.btn.btn-primary {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--paragraph-gray-2);
  }

  .btn {
    padding: 0;
  }

  .dropdown-menu {
    background-color: var(--warm-grey-1);
    border: 0.5px solid var(--warm-grey-3);
  }

  .link {
    color: var(--paragraph-gray-1) !important;
    text-decoration: none;
  }

  .name-text {
    @extend .font-style-default;
    @extend .font-size-standard;
    color: var(--paragraph-gray-2);
  }

  .nav-text {
    @extend .font-style-default;
    @extend .font-size-standard;
    color: var(--paragraph-gray-2);
    font-weight: bold;
  }

  .logo-accent {
    color: var(--logo-accent-color);
  }

  .logo {
    opacity: 5%;
  }

  @media all and (max-width: 1024px) {
    .link-high-light {
      color: var(--paragraph-gray-2) !important;
      text-decoration: none;
    }
  }

  @media all and (min-width: 1025px) {
    .link-high-light {
      border-bottom: solid var(--paragraph-gray-2) 2px;
      color: var(--paragraph-gray-2) !important;
      text-decoration: none;
    }
  }
}
