@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/style/common';

.projects-wrapper {
  box-sizing: revert;

  p {
    @include font-size-large-1;
  }

  .card-title {
    color: var(--header-grey);
  }

  .card-footer {
    border-top: 0;
  }

  .card-tile {
    border: 0;
    color: var(--accent-gray);
    margin: 0 auto;
    width: 50%;

    .typewriter-text {
      @extend .font-style-accent;
      @include font-size-large-1;
      color: var(--header-grey);
      font-weight: bold;
    }
  }

  p.card-text {
    @extend .font-size-standard;
  }

  .dangerous p {
    @extend .font-size-standard;
  }

  .dangerous a {
    color: var(--logo-accent-color) !important;
    font-weight: bold;
  }

  .text-fields {
    @extend %base-text-fields;
  }

  .no-link {
    color: var(--dark-accent-2);
    text-decoration: none;
  }

  .high-light-1 {
    color: var(--paragraph-gray-2);
    font-weight: bold;
  }

  .centred-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  *,
  ::after,
  ::before {
    box-sizing: content-box;
  }

  .text-fields {
    @extend %base-text-fields;
  }

  @media all and (max-width: 824px) {
    .card {
      border: 1px solid rgba(0, 0, 0, .125);
    }

    .box {
      display: block;
      float: none;
      width: 100%;
    }

    .card-style {
      padding: 1rem;
    }

    .high-light-1 {
      @include font-size-large-1;
      color: var(--paragraph-gray-2);
      font-weight: bold;
      margin: unset;
    }
  }

  @media all and (min-width: 1201px) {
    .image-container-base {
      width: 485px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .image-container {
      @extend .image-container-base;
      width: 485px;
    }

    .image-container-fourier {
      @extend .image-container-base;
      width: 385px;
    }
  }

  .text-container {
    margin-top: 10px;
    text-align: justify;
  }

  @media all and (min-width: 825px) {
    .card {
      border: 1px solid rgba(0, 0, 0, .125);
      height: 100%;
    }

    div.left-card {
      margin-right: 3px;
    }

    div.right-card {
      margin-left: 3px;
    }

    div.project-card {
      margin-top: 0.8rem;
    }

    .card-style {
      padding: 1.5rem;
    }

    .high-light-1 {
      @include font-size-large-2;
      color: var(--paragraph-gray-2);
      font-weight: bold;
      margin: unset;
    }
  }
}
