.font-style-default {
  font-family: avenirblack, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.font-style-accent {
  font-family: 'DejaVu Sans', Courier, Arial, Helvetica, sans-serif;
}

@mixin font-style-highlight {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

%font-style-highlight {
  @include font-style-highlight;
}

.font-size-standard {
  font-size: 14px;
}

.font-size-small {
  font-size: 11px;
}

.font-size-medium {
  font-size: 12px;
}

@mixin font-size-small {
  font-size: 11px;
}

@mixin font-size-medium {
  font-size: 12px;
}

%font-size-medium {
  @include font-size-medium;
}

.font-size-marge {
  font-size: 13px;
}

@mixin font-size-large-1 {
  font-size: 15px;
}

%font-size-large-1 {
  @include font-size-large-1;
}

@mixin font-size-large-2 {
  font-size: 18px;
}

%font-size-large-2 {
  @include font-size-large-2;
}

@mixin font-size-large-3 {
  font-size: 28px;
}

%font-size-large-3 {
  @include font-size-large-3;
}

@mixin font-size-extra {
  font-size: 38px;
}

%font-size-extra {
  @include font-size-extra;
}
