@import 'src/style/colors';
@import 'src/style/fonts';
@import 'src/style/common';

.about-wrapper {
  body {
    padding: 0;
    position: relative;
  }

  html,
  body {
    height: 100%;
  }

  p {
    @include font-size-large-1;
  }

  .card-tile {
    border: 0;
    color: var(--accent-gray);
    margin: 0 auto;
    width: 50%;

    .typewriter-text {
      @extend .font-style-accent;
      @include font-size-large-1;
      color: var(--header-grey);
      font-weight: bold;
    }
  }

  .text-fields {
    @extend %base-text-fields;
  }

  .high-light-1 {
    color: var(--paragraph-gray-2);
    font-weight: bold;
  }

  .theme-color {
    background-color: #cadebe;
    height: 32px;
    line-height: 31px;
    margin: 0 auto;

    p {
      @extend .font-style-accent;
      @extend .font-size-small;
      color: var(--footer-background);
      padding-right: 10px;
      text-align: right;
    }
  }

  @media all and (max-width: 824px) {
    .box {
      display: block;
      float: none;
      width: 100%;
    }

    .high-light-1 {
      @include font-size-large-1;
      color: var(--paragraph-gray-2);
      font-weight: bold;
      margin: unset;
    }
  }

  @media all and (min-width: 825px) {
    .high-light-1 {
      @include font-size-large-2;
      color: var(--paragraph-gray-2);
      font-weight: bold;
      margin: unset;
    }
  }
}
